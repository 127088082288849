<template>
  <v-container fluid class="px-6">

    <v-card>

      <v-card-title class="px-6 card-header white--text">
        <v-icon class="mr-3 white--text">mdi-format-list-bulleted</v-icon> <h3>Station List</h3>
      </v-card-title>

      <v-row>
        <v-col cols="4" class="px-9 pb-0">
          <v-select
          :items="['Continuous','Manual','Baseline']"
          v-model="modSelected"
          label="Monitoring Type"
          required
          outlined
          hide-details
          dense
          persistent-hint
          :menu-props="{ bottom: true, offsetY: true }"
          @change="getAllStation();getAllParams()"
          ></v-select>
        </v-col>
      </v-row>

      <div style="max-height: calc(100vh - 190px);overflow-y:auto;">
        <v-data-table
          :headers="stationHeader"
          :items="itemsWithIndex"
          :loading="loadingTable"
          class="elevation-2 headerDtSarawak px-6 pt-6"
        >

          <template v-slot:[`item.no`]="props">
            {{ props.item.index }}
          </template>
          <template v-slot:[`item.actions`]="props">
            <template>
              <v-icon
                small
                class="mr-2 green--text"
                @click="editStationItem(props.item)"
                >mdi-pencil</v-icon
              >
              <v-icon
                small
                class="red--text"
                text
                @click="deleteStation(props.item.STATION_ID)"
                >mdi-delete</v-icon
              >
            </template>
          </template>
        </v-data-table>
      </div>

    </v-card>

    <!------ Dialog Edit ------>
    <v-dialog persistent
    v-model="dialogEdit"
    max-width="70%" scrollable
    > 
      <v-card >

        <v-card-title class="card-header white--text">
          <v-icon class="white--text mr-2">mdi-text-box-edit</v-icon><h3>Edit Station : {{stationIdEdited}}</h3>
        </v-card-title>

        <v-card-text style="height: 80%" class="mb-4 pt-0">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-subheader class="pa-1 black--text">
                  Station ID 
                </v-subheader>
                <v-text-field
                  v-model="editedStationData.stationId"
                  readonly
                  hint="This field cannot be edited"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-subheader class="pa-1 black--text">
                  Location <span class="text-bold red--text ml-2">*</span>
                </v-subheader>
                <v-text-field
                  v-model="editedStationData.location"
                  dense
                  outlined
                  hide-details
                  :rules="[v => !!v || 'Station location name is required']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">State</v-subheader>
                <!-- <v-text-field
                  v-model="editedStationData.stateName"
                  dense
                  outlined hide-details
                ></v-text-field> -->
                <v-select
                :items="listState"
                :item-text="'name'"
                :item-value="'value'"
                v-model="editedStationData.stateName"
                required
                outlined
                dense
                :disabled="modSelected == ''"
                :rules="[v => !!v || 'State is required']"
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">
                  Longitude <span class="text-bold red--text ml-2">*</span>
                </v-subheader>
                <v-text-field
                  v-model="editedStationData.longitude"
                  dense
                  outlined hide-details
                  :rules="[v => !!v || 'Station longitude is required']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">
                  Latitude <span class="text-bold red--text ml-2">*</span>
                </v-subheader>
                <v-text-field
                  v-model="editedStationData.latitude"
                  dense
                  outlined hide-details
                  :rules="[v => !!v || 'Station latitude is required']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">Telecommunication Type</v-subheader>
                <v-text-field
                  v-model="editedStationData.telecommunicationType"
                  dense
                  outlined hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">Land Structure</v-subheader>
                <v-text-field
                  v-model="editedStationData.landStructure"
                  dense
                  outlined hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">Soil Type</v-subheader>
                <v-text-field
                  v-model="editedStationData.soilType"
                  dense
                  outlined hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">PIC Name</v-subheader>
                <v-text-field
                  v-model="editedStationData.picName"
                  dense
                  outlined hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">PIC Contact Number</v-subheader>
                <v-text-field
                  v-model="editedStationData.picPhone"
                  dense
                  outlined hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-subheader class="pa-1 black--text">
                  Time Alert Transmission <span class="text-bold red--text ml-2">*</span>
                </v-subheader
                >
                <v-select
                  :items="listTime"
                  :item-text="'name'"
                  :item-value="'value'"
                  v-model="editedStationData.timeAlertTransmission  "
                  required
                  outlined 
                  dense 
                  :rules="[v => !!v || 'Time alert is required']"
                  :menu-props="{ bottom: true, offsetY: true }"
                  hide-details
                ></v-select>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-title class="card-header white--text">
          <v-icon class="white--text mr-2">mdi-image-edit</v-icon><h3>Edit Picture</h3>
        </v-card-title>

        <v-card-text>
          <br>
          <v-card outlined
          :loading="loadingUploadImage"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-subheader class="pa-1 black--text">Station</v-subheader>
                  <a v-if="editedStationData.stationPicture != ''" :href="editedStationData.stationPicture" target="_blank">
                    <v-icon small class="ml-3 primary--text" style="text-decoration:none">mdi-message-image-outline</v-icon>
                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                  </a>
                  <a v-if="editedStationData.stationPicture == ''" style="cursor:default;">
                    <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                    <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                  </a> 
                  <v-file-input
                    type="file"
                    :placeholder="editedStationData.stationPicture != null? editedStationData.stationPicture.split('_').pop() : ''"
                    @change="uploadPicture(uploadPic.file1, 'stationPicture')"
                    v-model="uploadPic.file1"
                    clearable
                    dense
                    outlined
                    >test</v-file-input
                  >
                </v-col>

                <v-col cols="12" sm="6">
                  <v-subheader class="pa-1 black--text">Deployment</v-subheader>
                  <a v-if="editedStationData.deploymentPicture != ''" :href="editedStationData.deploymentPicture" target="_blank">
                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                  </a>
                  <a v-if="editedStationData.deploymentPicture == ''" style="cursor:default;">
                    <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                    <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                  </a>
                  <v-file-input
                    type="file"
                    :placeholder="editedStationData.deploymentPicture != null? editedStationData.deploymentPicture.split('_').pop() : ''"
                    @change="uploadPicture(uploadPic.file2, 'deploymentPicture')"
                    v-model="uploadPic.file2"
                    dense
                    outlined
                    >test</v-file-input
                  >
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-subheader class="pa-1 black--text">Upstream</v-subheader>
                  <a v-if="editedStationData.upstreamPicture != ''" :href="editedStationData.upstreamPicture" target="_blank">
                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                  </a>
                  <a v-if="editedStationData.upstreamPicture == ''" style="cursor:default;">
                    <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                    <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                  </a> 
                  <v-file-input
                    type="file"
                    :placeholder="editedStationData.upstreamPicture"
                    @change="uploadPicture(uploadPic.file3, 'upstreamPicture')"
                    v-model="uploadPic.file3"
                    dense
                    outlined
                    >test</v-file-input
                  >
                </v-col>
                <v-col cols="12" sm="6">
                  <v-subheader class="pa-1 black--text">Downstream</v-subheader>
                  <a v-if="editedStationData.downStreamPicture != ''" :href="editedStationData.downStreamPicture" target="_blank">
                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                  </a>
                  <a v-if="editedStationData.downStreamPicture == ''" style="cursor:default;">
                    <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                    <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                  </a>
                  <v-file-input
                    type="file"
                    :placeholder="editedStationData.downStreamPicture != null? editedStationData.downStreamPicture.split('_').pop() : ''"
                    @change="uploadPicture(uploadPic.file4, 'downStreamPicture')"
                    v-model="uploadPic.file4"
                    dense
                    outlined
                    >test</v-file-input
                  > 
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          
        </v-card-text>


        <v-card-actions class="card-footer">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogEdit=false"
            color="error"
          >
            CANCEL
          </v-btn>
          <v-btn
            @click="postEditStation"
            color="button" class="white--text px-4 mx-3"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!------ Dialog Delete ------>
    <v-dialog persistent v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="text-h5 white--text error">
          <v-icon class="white--text mr-2">mdi-delete-circle</v-icon> Delete Station : {{delSelected}}
        </v-card-title>

        <v-card-text class="pa-5 d-flex justify-center">
          <h3>Are you sure you want to delete this station? </h3>
        </v-card-text>


        <v-card-actions class="card-footer">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close">Cancel</v-btn>
          <v-btn color="error" class="white--text px-4 mx-3" @click="postDeleteStation">YES</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popups/Dialogs Success Param Added-->
    <v-dialog
    v-model="dialogSuccessStationEdited"
    width="500"
    >
      <v-card>

          <v-card-title class="text-h5 white--text button">
            <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Form has been submitted
          </v-card-title>

          <v-card-text class="pa-5 d-flex justify-center">
            <h3>Station has been successfully edited</h3> <v-icon class="mb-1 ml-1">mdi-check-circle-outline</v-icon> 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogSuccessStationEdited = false"
            >
              Close
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Popups/Dialogs Error Add Station-->
    <v-dialog
    v-model="dialogErrorEditStation"
    width="500"
    >
      <v-card>

          <v-card-title class="text-h5 white--text error">
            <v-icon class="white--text mr-2">mdi-text-box-remove</v-icon>Form failed to submit
          </v-card-title>

          <v-card-text class="pa-5 d-flex justify-center">
            <h3>Something went wrong. Please make sure all required field is filled and try again <v-icon class="mb-1 ml-1">mdi-alert-circle</v-icon> </h3> 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialogErrorEditStation = false"
            >
              Close
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import axios from "axios";
import appMixins from '@/mixins/globalMixins'

export default {
  data: () => ({

    modSelected: "Continuous",

    loadingTable: false,

    stationIdEdited: null,

    dialogSuccessStationEdited: false,
    dialogErrorEditStation: false,
    listState: [],

    listTime: [
      {name: "15 Minutes", value: "00:15:00"},
      {name: "30 Minutes", value: "00:30:00"},
      {name: "1 Hour", value: "00:00:00"},
    ],
    loadingUploadImage: false,
    urlPic1: null,
    uploadPic: {
      file1: null,
      file2: null,
      file3: null,
      file4: null,
    },
    delSelected: "",
    dialogEdit: false,
    dialogDelete: false,
    stationHeader: [
      { text: "NO.", align: "start", value: "no" },
      { text: "STATION ID", value: "STATION_ID" },
      { text: "LOCATION", value: "LOCATION" },
      { text: "ACTIONS", value: "actions", sortable: false },
    ],
    stationData: [],

    editedStationData: {
      stationId: "",
      stationStatus: "",
      alertStatus: "",
      location: "",
      longitude: "",
      latitude: "",
      stateName: "",
      telecommunicationType: "",
      landStructure: "",
      soilType: "",
      picName: "",
      picPhone: "",
      timeAlertTransmission  : "",
      stationPicture: "",
      deploymentPicture: "",
      upstreamPicture: "",
      downStreamPicture: "",
      STATION_ID_ACTIVE: "",
    },

    filterstationData: [],
    paramArray: [],
    paramItems: {
      DO_CON_ACTIVE: "",
      "4_4_DDT_ACTIVE": "",
      AG_ACTIVE: "",
      AL_ACTIVE: "",
      ALDRIN_DIELDRIN_ACTIVE: "",
      AS_ACTIVE: "",
      B_ACTIVE: "",
      BA_ACTIVE: "",
      BHC_ACTIVE: "",
      BOD_ACTIVE: "",
      BR_ACTIVE: "",
      CA_ACTIVE: "",
      CCE_ACTIVE: "",
      CD_ACTIVE: "",
      CDOM_ACTIVE: "",
      CHLORDANE_ACTIVE: "",
      CHLOROPHYLL_A_ACTIVE: "",
      CL_ACTIVE: "",
      CL2_ACTIVE: "",
      CN_ACTIVE: "",
      CO2_ACTIVE: "",
      COD_ACTIVE: "",
      COLOUR_ACTIVE: "",
      CONDUCTIVITY_ACTIVE: "",
      CR_ACTIVE: "",
      CR_III_ACTIVE: "",
      CR_IV_ACTIVE: "",
      CU_ACTIVE: "",
      DEPTH_ACTIVE: "",
      DO_SAT_ACTIVE: "",
      DOC_ACTIVE: "",
      E_COLI_ACTIVE: "",
      EC_ACTIVE: "",
      ENDOSULFAN_ACTIVE: "",
      F_ACTIVE: "",
      FC_ACTIVE: "",
      FDOM_ACTIVE: "",
      FE_ACTIVE: "",
      FLOWRATE_ACTIVE: "",
      GROSS_ALPHA_ACTIVE: "",
      GROSS_BETA_ACTIVE: "",
      HARDNESS_ACTIVE: "",
      HEDONAL_2_4_D_ACTIVE: "",
      HEPTACHLOR_EPOXIDE_ACTIVE: "",
      HG_ACTIVE: "",
      K_ACTIVE: "",
      LINDANE_ACTIVE: "",
      MBAS_ACTIVE: "",
      MG_ACTIVE: "",
      MN_ACTIVE: "",
      NA_ACTIVE: "",
      NH3N_ACTIVE: "",
      NH4_ACTIVE: "",
      NI_ACTIVE: "",
      NO2_ACTIVE: "",
      NO3_ACTIVE: "",
      OG_EDIBLE_ACTIVE: "",
      OG_MINERAL_ACTIVE: "",
      P_ACTIVE: "",
      PARAQUAT_ACTIVE: "",
      PB_ACTIVE: "",
      PCB_ACTIVE: "",
      pH_ACTIVE: "",
      PHENOL_ACTIVE: "",
      PO4_3_ACTIVE: "",
      RA226_ACTIVE: "",
      S_ACTIVE: "",
      SALINITY_ACTIVE: "",
      SE_ACTIVE: "",
      SN_ACTIVE: "",
      SO4_ACTIVE: "",
      SOLAR_BATTERY_ACTIVE: "",
      SILVEX_2_4_5_TP_ACTIV: "",
      SR_90_ACTIVE: "",
      TC_ACTIVE: "",
      TDS_ACTIVE: "",
      TEMPERATURE_ACTIVE: "",
      TOC_ACTIVE: "",
      TRIOXONE_2_4_5_T_ACTIVE: "",
      TSS_ACTIVE: "",
      TURBIDITY_ACTIVE: "",
      U_ACTIVE: "",
      VELOCITY_ACTIVE: "",
      ZN_ACTIVE: "",
      TRYPHPTOPHAN_ACTIVE: ""
    },
    stationDetails : {},
    editedIndex: -1,

    // Scroll Control
    // type: 'element',
    // selected: 'dialog',
    paramList: []
  }),

  mounted() {
    this.getAllStation();
    this.getAllParams();
    this.getListState();
    // this.getAllParamActive();
    this.editedStationData.downStreamPicture;
  },

  methods: {

    getListState(){
        axios.get(this.globalUrl+'mqm2/users/alldropdowns', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {

          // console.log("masuk sini")
         
          this.listState = response.data.states;

         
        })
        .catch(error => {
            console.log(error);
        })
      },

    getAllParams(){

      this.paramList = [];

      let urlParams = "";
      if(this.modSelected == "Continuous"){
        urlParams = "mqm2/miqims/params"
      }
      else {
        urlParams = "mqm2/miqims/params_manual"
      }

      axios
      .get(this.globalUrl+urlParams, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
        let data = response.data;

        for(let i in data){
          if(data[i].reading.includes("WQI")==false && data[i].reading.includes("SI_BOD")==false && data[i].reading.includes("SI_COD")==false 
          && data[i].reading.includes("SI_DO_SAT")==false && data[i].reading.includes("SI_NH3N")==false && data[i].reading.includes("SI_TSS")==false 
          && data[i].reading.includes("SI_pH")==false)
          {
            this.paramList.push(data[i].reading + "_ACTIVE");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },

    uploadPicture(data, fileKey) {

      this.loadingUploadImage = true;

      let formData = new FormData();

      formData.append("files", data);

      axios
        .post(this.globalUrl+'mqm2/miqims/upload', formData, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // this.stationData = response.data.filepath
          this.editedStationData[fileKey] = response.data.filepath;
          // console.log(this.editedStationData[fileKey].split('_').pop())
          // console.log(this.editedStationData[fileKey])

          console.log(response);
          this.loadingUploadImage = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingUploadImage = false;
        });
    },

    getAllStation() {

      this.loadingTable = true;
      this.stationData = [];

      let urlStations = "";
      if(this.modSelected == "Continuous"){
        urlStations = "mqm2/miqims/stations"
      }
      else if(this.modSelected == "Manual"){
        urlStations = "mqm2/miqims/stations_manual"
      }
      else if(this.modSelected == "Baseline"){
        urlStations = "mqm2/miqims/stations_baseline"
      }

      axios
        .get(this.globalUrl+urlStations, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.stationData = response.data;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

    getAllParamActive(item){

      this.filterstationData = [];

      let keyDataItem = Object.keys(item);

      let urlStations = "";
      if(this.modSelected == "Continuous"){
        urlStations = "mqm2/miqims/stations"
      }
      else if(this.modSelected == "Manual"){
        urlStations = "mqm2/miqims/stations_manual"
      }
      else if(this.modSelected == "Baseline"){
        urlStations = "mqm2/miqims/stations_baseline"
      }

      axios
      .get(this.globalUrl+urlStations, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let keyData = Object.keys(response.data[0]);

        for (let i = 0; i < keyData.length; i++) {
          if (keyData[i].includes("ACTIVE") == true) {
              this.filterstationData.push(keyData[i]);
          }
        }

        for (let i = 0; i < this.filterstationData.length; i++) {
          for (let x = 0; x < keyDataItem.length; x++) {
            if (this.filterstationData[i] == keyDataItem[x]) {
              this.paramItems[this.filterstationData[i]] = item[keyDataItem[x]];
            }
          }
        }

      })
      .catch((error) => {
        console.log(error);
      });
      

    },

    postDeleteStation() {

      let urlDeleteStation = "";
      if(this.modSelected == "Continuous"){
        urlDeleteStation = "mqm2/miqims/delstation?stationid="+this.delSelected
      }
      else {
        urlDeleteStation = "mqm2/miqims/delstation_manual?stationid="+this.delSelected
      }

      axios
        .get(
          this.globalUrl+urlDeleteStation,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          // let temp = this.stationData.findIndex(
          //   (item) => item.stationId === this.delSelected
          // );
          // this.stationData.splice(temp, 1);
          // this.close();
          this.dialogDelete = false;
          this.getAllStation();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editStationItem(item) {

      console.log(item);
     
      this.stationIdEdited = item.STATION_ID;
      this.dialogEdit = true;
      this.stationDetails = item;

      //this.editedStationData = this.stationDetails;

      this.editedStationData.stationId = item.STATION_ID;
      this.editedStationData.stationStatus = item.STATION_STATUS;
      this.editedStationData.alertStatus = item.ALERT_STATUS;
      this.editedStationData.location = item.LOCATION;
      this.editedStationData.stateName = item.STATE_NAME;
      this.editedStationData.longitude = item.LONGITUDE?.toString();
      this.editedStationData.latitude = item.LATITUDE?.toString();
      this.editedStationData.telecommunicationType = item.TELECOMMUNICATION_TYPE;
      this.editedStationData.landStructure = item.LAND_STRUCTURE;
      this.editedStationData.soilType = item.SOIL_TYPE;
      this.editedStationData.picName = item.PIC_NAME;
      this.editedStationData.picPhone = item.PIC_PHONE;
      this.editedStationData.timeAlertTransmission = item.TIME_ALERT_TRANSMISSION;
      this.editedStationData.STATION_ID_ACTIVE = item.STATION_ID;

      this.editedStationData.stationPicture = item.STATION_PICTURE;
      this.editedStationData.deploymentPicture = item.DEPLOYMENT_PICTURE;
      this.editedStationData.upstreamPicture = item.UPSTREAM_PICTURE;
      this.editedStationData.downStreamPicture = item.DOWNSTREAM_PICTURE;

      for(let i in this.paramList){
        this.editedStationData[this.paramList[i]] = item[this.paramList[i]];
      }

    },

    postEditStation() {

      console.log(JSON.stringify(this.editedStationData));

      if (this.editedStationData.location != "" && this.editedStationData.longitude != ""
      && this.editedStationData.latitude != "") {

        let urlEditStations = "";
        if(this.modSelected == "Continuous"){
          urlEditStations = "mqm2/miqims/editstation"
        }
        else {
          urlEditStations = "mqm2/miqims/editstation_manual"
        }

        axios.put(this.globalUrl+urlEditStations, this.editedStationData, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.getAllStation();
          this.dialogSuccessStationEdited = true;
          this.dialogErrorEditStation = false;
          this.dialogEdit = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.dialogSuccessStationEdited = false;
          this.dialogErrorEditStation = true;
        });
        
      }
      else{
        this.dialogSuccessStationEdited = false;
        this.dialogErrorEditStation = true;
      }

      
    },

    scrollTo(){
      // document.getElementById("topEditCard").scrollIntoView()
    },

    deleteStation(val) {
      this.delSelected = val;
      this.dialogDelete = true;
    },

    close() {
      this.dialogEdit = false;
      this.dialogDelete = false;
      this.editedStationData.stationPicture = "";
      this.editedStationData.deploymentPicture = "";
      this.editedStationData.upstreamPicture = "";
      this.editedStationData.downStreamPicture = "";
      this.stationIdEdited = null;
    }
       
  },

  computed: {
    itemsWithIndex() {
      return this.stationData.map((stationData, index) => ({
        ...stationData,
        index: index + 1,
      }));
    }
  },

  created() {
    for (let key in this.paramItems) {
      this.paramArray.push({ name: key, param: key?.split("_ACTIVE")[0], value: null, min: null, max: null, outlierMin: null, outlierMax: null });
    }
  },

  
};
</script>

<style lang="scss" scoped>
</style>